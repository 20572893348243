<template>
  <div>
    <h1 class="mb-0">Reset Password</h1>
    <p>Enter your new Password here.</p>
    <form class="mt-4" @submit.prevent="recoverPassword">

      <div class="form-group">
        <label for="exampleInputEmail1">New Password</label>
        <input type="password" v-model="password" class="form-control mb-0" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter New Password">
        <label for="exampleInputEmail1">Confirm Password</label>
        <input type="password" v-model="confirmPassword" class="form-control mb-0" id="inputConfirmPassword" aria-describedby="emailHelp" placeholder="Confirm Password">
      </div>

      <div class="d-inline-block w-100">
        <button type="submit" class="btn btn-primary float-right">Reset Password</button>
      </div>

    </form>
    <div v-if="getErrorStatus">
      <b-alert :show="true" variant=" " dismissible fade class="text-white bg-danger">
        <div class="iq-alert-icon">
          <i class="ri-information-line"></i>
        </div>
        <div class="iq-alert-text">{{getErrorMessage}}</div>
      </b-alert>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ResetPassword',
  components: {
  },
  computed: {
    ...mapGetters({
      getErrorStatus: 'getErrorStatus',
      getErrorMessage: 'getErrorMessage'
    })

  },
  data () {
    return {
      password: '',
      confirmPassword: '',
      otp: ''
    }
  },
  methods: {
    ...mapActions({
      SET_PASSWORD: 'SET_PASSWORD'
    }),
    recoverPassword () {
      const response = {
        password: this.password,
        otp: this.otp
      }
      this.SET_PASSWORD({
        payload: response,
        success: () => {
          this.$router.push({ name: 'auth1.sign-in1' })
        }
      })
    }

  },
  mounted () {
    this.otp = this.$route.params.id
  }
}
</script>
